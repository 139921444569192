<template>
  <div class="warehouse-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      ref="searchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.name"
            placeholder="员工名字"
            clearable
          />
        </el-form-item>

        <el-form-item class="typeInput">
          <el-select
            v-model="searchForm.types"
            placeholder="类型"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.type"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日期" class="typeInput">
          <el-date-picker
            v-model="time"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDetailList(null, 1)">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap">
        <h5>公司列表</h5>
        <el-table
          height="calc(100vh - 210px)"
          :data="dataList"
          ref="dataList"
          border
          @selection-change="$selectionChange($event, 'dataList')"
          @row-click="$clickRow($event, 'dataList', getDetailList($event, 1))"
          @select="$select(arguments, 'dataList')"
          @select-all="$clearSelection('dataList')"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="公司列表"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'dataList')"
          @current-change="$currentChange($event, 'dataList')"
          :current-page="dataListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="dataListSize"
          :total="dataListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <h5>公司员工用餐对账信息</h5>
        <div class="listBtn">
          <span
            >公司补助:<b>{{ this.totalList.companyIncome }}</b></span
          >
          <span
            >消费补助:<b>{{ this.totalList.consumption }}</b></span
          >
          <span
            >退货退还:<b>{{ this.totalList.cancel }}</b></span
          >
          <span
            >公司充值:<b>{{ this.totalList.recharge }}</b></span
          >
          <span
            >公司补助转余额:<b>{{ this.totalList.subsidyToBalance }}</b></span
          >
        </div>

        <el-table
          :data="detailList"
          ref="detailList"
          border
          stripe
          v-loading="detailListLoading"
          @row-click="$clickRow($event, 'detailList')"
          @selection-change="$selectionChange($event, 'detailList')"
          style="width: 100%"
        >
          <el-table-column
            prop="employeesName"
            header-align="center"
            align="center"
            label="员工名字"
          />
          <el-table-column
            prop="mobile"
            header-align="center"
            align="center"
            label="手机号码"
          />
          <el-table-column
            prop="balance"
            header-align="center"
            align="center"
            label="补助使用"
          />
          <el-table-column
            prop="type"
            header-align="center"
            align="center"
            label="实付金额"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type === 0" type="info" size="mini"
                >公司补助</el-tag
              >
              <el-tag v-else-if="scope.row.type === 1" size="mini"
                >补助使用</el-tag
              >
              <el-tag v-else-if="scope.row.type === 2" size="mini"
                >补助退还</el-tag
              >
              <el-tag v-else-if="scope.row.type === 3" size="mini"
                >公司充值</el-tag
              >
              <el-tag v-else-if="scope.row.type === 99" size="mini"
                >公司补助转余额</el-tag
              >
            </template> </el-table-column
          >>
          <el-table-column
            prop="createTime"
            header-align="center"
            align="center"
            label="付款时间"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'detailList')"
          @current-change="$currentChange($event, 'detailList')"
          :current-page="detailListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="detailListSize"
          :total="detailListCount"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { threeListMixin, normal } from '@/mixins';

export default {
  mixins: [normal, threeListMixin],
  data() {
    return {
      searchForm: {
        name: '',
        startTime: '',
        endTime: '',
        types: [],
      },
      companyId: 0,
      dataList: [],
      detailList: [],
      totalList: {
        cancel: '',
        companyIncome: '',
        consumption: '',
        recharge: '',
        subsidyToBalance: '',
      },
      time: [],
      typeList: [
        { id: 0, type: '公司补助' },
        { id: 1, type: '补助使用' },
        { id: 2, type: '补助退还' },
        { id: 3, type: '公司充值' },
        { id: 99, type: '公司补助转余额' },
      ],
    };
  },
  components: {},
  activated() {
    this.getDataList();
  },
  methods: {
    //获取数据列表
    getDataList() {
      this.$api({
        url: '/tc/company/list',
        params: {
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.list.records;
            if (data && data.list.records?.length) {
              this.companyId = this.dataList[0].id;
              this.dataListCount = data.list.total;
              this.$nextTick(() => {
                this.$refs.dataList.toggleRowSelection(
                  data.list.records[0],
                  true,
                );
              });
            }
          } else {
            this.dataList = [];
          }
        },
      });
    },
    // 获取右侧数据列表
    getDetailList(row, page) {
      this.companyId = row && row.id ? row.id : this.companyId;
      if (this.time?.length) {
        this.searchForm.startTime = this.time[0];
        this.searchForm.endTime = this.time[1];
      } else {
        this.searchForm.startTime = null;
        this.searchForm.endTime = null;
      }
      this.$api({
        url: '/tc/employeesbalance/list',
        params: {
          page: page ? page : this.detailListIndex,
          limit: this.detailListSize,
          ...this.searchForm,
          companyId: this.companyId,
        },

        after: (data) => {
          if (data && data.code === 0) {
            this.detailList = data.page.records;
            this.detailListCount = data.page.total;
            this.totalList = data.totalVo;
          } else {
            this.detailList = [];
            this.totalList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style lang="scss">
/* element滚动条组件 隐藏水平滚动条 */
.sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}

/*.is-horizontal {*/
/*  display: none;*/
/*}*/
/*.el-row {*/
/*  margin-bottom: 20px;*/
/*&:last-child {*/
/*   margin-bottom: 0;*/
/* }*/
/*}*/
/*.el-col {*/
/*  border-radius: 4px;*/
/*}*/
/*.bg-purple-dark {*/
/*  background: #99a9bf;*/
/*}*/
.bg-purple {
  background: #d3dce6;
}
/*.bg-purple-light {*/
/*  background: #e5e9f2;*/
/*}*/
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/*.row-bg {*/
/*  padding: 10px 0;*/
/*  background-color: #f9fafc;*/
/*}*/
</style>
